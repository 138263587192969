// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';

p, li {
    font-family: $font-family-2;
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-burst {
    display: flex;
    justify-content: space-around;
    padding: 50px 5%;

    .burst1 {
        background: #2269ea;
    }
    .burst2 {
        background: #0e4fc5;
    }
    .burst3 {
        background: #09388e;
    }

    .burst {
        width: 30%;
        transition: .5s;
        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        height: calc(100vw * 250 / 1920);
        p {
            text-align: center;
            font-weight: bold;
            font-size: $font-size-30;
            line-height: 1.3;
            color: #fff;
            font-family: $font-family-1;
        }
        &:hover {
            background: #000;
        }
    }
}

#blocIntro {
    padding: 25px 7% 50px 7%;

    p {
        font-weight: normal;
        font-size: $font-size-24;
    }
}

#blocBackground {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 95px 2%;
    text-align: center;

    p {
        color: #fff;
        font-size: $font-size-30;
        line-height: 1.5;
        font-weight: 500;
    }
}

#blocListe {
    display: flex;
    justify-content: space-evenly;
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;
    padding: 70px 2%;

    li {
        color: #fff;
        font-size: $font-size-24;
        font-weight: normal;
        text-transform: initial;
        margin-bottom: -10px;
    }
}

#blocLogo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 35px 2%;
}

/*******************************************************************************
* AIGUISAGE
*******************************************************************************/

#blocIntro_aiguisage {
    padding: 25px 7% 50px 7%;
    text-align: center;

    p {
        font-weight: normal;
        font-size: $font-size-24;
    }
}

#section-burst {
    .noLink {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#blocBackground_aiguisage {
    background: url("../images/aiguisage_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 95px 2%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;

    h3 {
        text-align: right;
        font-weight: bold;
        font-size: $font-size-30;
    }
    p {
        color: #fff;
        font-size: $font-size-24;
        font-weight: 500;
    }
}

.main-content {
    position: relative;
    border-bottom: 25px solid #fff;
    border-top: 25px solid #fff;
    border-right: 25px solid #fff;
    border-left: 25px solid #fff;

    .owl-theme {
        .custom-nav {
            position: absolute;
            top: 20%;
            left: 0;
            right: 0;

            .owl-prev, .owl-next {
                position: absolute;
                height: 100px;
                color: inherit;
                background: none;
                border: none;
                z-index: 100;

                i {
                    font-size: 2.5rem;
                    color: #000;
                }
            }

            .owl-prev {
                left: 0;
                top: 125px;
            }

            .owl-next {
                right: 0;
                top: 125px;
            }
        }
    }
}

#blocListe_aiguisage {
    display: flex;
    justify-content: space-evenly;
    background: url("../images/aiguisage_section04_bg.jpg") no-repeat;
    background-size: cover;
    padding: 70px 2%;

    p {
        color: #fff;
        font-size: $font-size-24;
        font-weight: 500;
    }
}

/*******************************************************************************
* FABRICATION D'OUTILS
*******************************************************************************/

#blocBackground_fabrication {
    background: url("../images/fabrication_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 85px 2%;
    text-align: center;

    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        padding-bottom: 30px;
    }
    p {
        color: #fff;
        font-size: $font-size-24;
        font-weight: normal;
    }
}

#blocIntro_fabrication {
    padding: 50px 5%;
    text-align: center;

    p {
        font-weight: normal;
        font-size: $font-size-24;
        color: #000;
    }
}

#blocListe_fabrication {
    display: flex;
    justify-content: space-evenly;
    background: url("../images/fabrication_section05_bg.jpg") no-repeat;
    background-size: cover;
    padding: 70px 2%;

    li {
        color: #fff;
        font-size: $font-size-30;
        font-weight: normal;
        text-transform: initial;
        margin-bottom: -15px;
    }
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#blocListe_produits {
    background: url("../images/produits_section02_bg.jpg") no-repeat;
    background-size: cover;
    padding: 125px 2%;
    .liste {
        display: flex;
        justify-content: space-evenly;
    }
    h3{
        color: #000;
        font-size: $font-size-30;
        font-weight: bold;
        padding: 0 80px 35px 80px;
    }
    p {
        color: #000;
        font-size: $font-size-24;
        font-weight: 500;
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#titreRealisation {
    padding: 50px 5%;

    h3 {
        color: #000;
        font-size: $font-size-30;
        font-weight: bold;
    }
}
#blocRealisation {
    .bloc {
        display: flex;
        align-items: stretch;
        padding-bottom: 50px;
        &:last-child {
            padding-bottom: 0;
        }
        .image {
            width: 50%;
            background-position: center;
            background-size: cover;
        }
        .texte {
            background-color: #0e4fc5;
            padding: 70px;
            display: flex;
            flex-direction: column;
            width: 100%;
            h3 {
                color: #fff;
                font-size: $font-size-30;
                font-weight: bold;
                padding-bottom: 25px;
            }
            p {
                color: #fff;
                font-size: $font-size-24;
                font-weight: 500;
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocCoordonnees {
    padding: 50px 5%;
    display: flex;
    justify-content: space-around;
    .heuresjours {
        display: flex;
        justify-content: space-between;
    }
    h3 {
        color: #2269ea;
        font-size: $font-size-30;
        font-weight: bold;
        padding-bottom: 25px;
    }
    p {
        color: #000;
        font-size: $font-size-24;
        font-weight: 500;
    }
}

.module-google-map {
    h3#firstHeading {
        color: #2269ea;
        font-size: $font-size-30;
        font-weight: bold;
    }
    a {
        color: #000;
        font-size: $font-size-24;
        font-weight: normal;
        &:hover {
            font-weight: 500;
            color: #000;
        }
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1820px) {

#blocIntro p br, #blocIntro_fabrication p br, #blocIntro_aiguisage p br {
    display: none;
}
}

@media screen and (max-width: 1600px) {

#blocListe_produits p {
    font-size: 1.1rem;
}
}

@media screen and (max-width: 1555px) {

#blocBackground p br {
    display: none;
}
#blocLogo .logo {
    padding: 25px;
}
}

@media screen and (max-width: 1480px) {

#blocListe_aiguisage p {
    line-height: 1.5;
    margin-bottom: 25px;
}
}

@media screen and (max-width: 1350px) {

#blocListe_produits p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 25px;
}
#blocBackground_aiguisage .right-side {
    width: 60%;
}
#blocBackground_aiguisage p br {
    display: none;
}
#blocListe_aiguisage p, #blocListe_produits p{
    font-size: 1rem;
}
}

@media screen and (max-width: 1100px) {

#blocListe_fabrication li {
    margin-bottom: 25px;
    line-height: 1.5;
    font-size: 1.4rem;
}
}

@media screen and (max-width: 1024px) {

#section-burst .burst p {
    font-size: 1.2rem;
}
#blocListe li {
    margin-bottom: 20px;
    line-height: 1.2;
}
#blocListe .left-side {
    width: 70%;
}
nav.module-menu {
    height: 90px;
}
nav.module-menu .menu-navbar {
    background: #2269ea;
    height: 90px;
}
nav.module-menu .menu-navbar .wrapper.left {
    display: block;
}
nav.module-menu .menu-navbar.nav-invisible {
    top: -100px;
}
#blocListe_fabrication li {
    font-size: 1.5rem;
}
#blocListe_aiguisage p, #blocListe_produits p {
    font-size: 1.2rem;
}
}

@media screen and (max-width: $size-sm-max) {

#blocListe .right-side {
    width: 50%;
}
.slideshow-wrap .caption {
    top: 55%;
    left: 45%;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 165px;
}
#blocRealisation .bloc {
    flex-direction: column;
    padding-bottom: 100px;
}
#blocRealisation .bloc .image {
    height: 365px;
    margin: auto;
}
#blocListe_produits .liste {
    flex-wrap: wrap;
}
#section-burst .burst p {
    font-size: 1rem;
}
#blocListe_aiguisage {
    flex-wrap: wrap;
}
}

@media screen and (max-width: $size-xs-max) {

#section-burst {
    flex-direction: column;
    padding: 50px 5% 25px 5%;
}
#section-burst .burst {
    width: 100%;
    height: calc(250vw * 250 / 1920);
    margin-bottom: 15px;
}
#blocListe {
    flex-direction: column;
}
#blocListe .left-side, #blocListe .right-side {
    width: 100%;
}
#section-burst .burst p {
    font-size: 1.5rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 145px;
}
#blocRealisation .bloc .image {
    width: 100%;
}
#blocRealisation .bloc .texte {
    padding: 60px 35px;
}
#blocCoordonnees {
    flex-direction: column;
}
#blocCoordonnees .left-side {
    padding-bottom: 35px;
}
#blocListe_fabrication {
    padding: 50px 5%;
    flex-direction: column;
}
#blocIntro_fabrication {
    padding: 0px 5%;
}
#blocBackground_aiguisage {
    flex-direction: column;
}
#blocBackground_aiguisage h3 {
    text-align: left;
}
#blocBackground_aiguisage .right-side {
    width: 100%;
}
#blocBackground_aiguisage p {
    padding-top: 50px;
}
#blocBackground_aiguisage {
    padding: 95px 5%;
}
}
